<template>
  <footer class="footer" id="footer">
    <div class="container px-lg-0">
      <hr class="my-5" />

      <div class="row">
        <div class="col-md-6">
          <img
            alt="Merck"
            class="foot-logo"
            src="@/assets/images/merck-logo.png"
          />
        </div>

        <div
          class="col-md-6 d-flex justify-content-md-end px-3 my-md-0 my-4 h-25"
        >
          <a
            data-bs-toggle="modal"
            data-bs-target="#interstitial-modal"
            href="https://www.essentialaccessibility.com/merck"
            class="interstitial-link me-4"
          >
            <img
              src="@/assets/images/accessibility.png"
              alt="Accessibility icon"
              class="img-fluid"
          /></a>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <ul
            class="
              list-group list-group-horizontal list-unstyled
              ps-lg-4
              mt-4
              mb-0
              foot-nav
            "
          >
            <li class="list-group-item ps-0">
              <a
                class="interstitial-link"
                data-bs-toggle="modal"
                data-bs-target="#interstitial-modal"
                href="https://www.merck.com/"
                target="_blank"
                >Merck.com</a
              >
            </li>
            <li class="list-group-item">
              <a
                class="interstitial-link"
                href="http://msdgrant.com/"
                data-bs-toggle="modal"
                data-bs-target="#interstitial-modal"
                target="_blank"
                >Merck’s grant submission tool</a
              >
            </li>
            <li class="list-group-item">
              <a
                class="interstitial-link"
                href="http://msdimegrants.com/"
                data-bs-toggle="modal"
                data-bs-target="#interstitial-modal"
                target="_blank"
                >Medical education</a
              >
            </li>
            <li class="list-group-item">
              <a
                class="interstitial-link"
                href="https://www.msd.com/company-overview/responsibility/"
                data-bs-toggle="modal"
                data-bs-target="#interstitial-modal"
                target="_blank"
                >Charitable program</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <ul
            class="
              list-group list-group-horizontal list-unstyled
              ps-lg-4
              mt-0
              mb-4
              foot-nav
            "
          >
            <li class="list-group-item ps-0">
              <a
                class="interstitial-link"
                data-bs-toggle="modal"
                data-bs-target="#interstitial-modal"
                href="https://www.msdprivacy.com/us/en/"
                target="_blank"
                >Privacy</a
              >
            </li>
            <li class="list-group-item">
              <a
                class="interstitial-link"
                data-bs-toggle="modal"
                data-bs-target="#interstitial-modal"
                href="https://www.merck.com/terms-of-use/"
                target="_blank"
                >Terms of use</a
              >
            </li>
            <li class="list-group-item">
              <a
                class="interstitial-link"
                data-bs-toggle="modal"
                data-bs-target="#interstitial-modal"
                href="https://www.msdprivacy.com/us/en/cookie-privacy-policy.html"
                target="_blank"
                >Cookie policy</a
              >
            </li>
            <li class="list-group-item">
              <router-link to="/Sitemap">Sitemap</router-link>
            </li>
            <li id="cookies-btn" class="list-group-item">
              <!-- OneTrust Cookies Settings button start -->
              <a class="ot-sdk-show-settings">Cookie preferences</a>
              <!-- OneTrust Cookies Settings button end-->
            </li>
          </ul>
        </div>
      </div>

      <div class="row copyright-info">
        <div class="col-12 mx-lg-4">
          <p class="mb-5 text-gray">
            Copyright &copy; {{ currYear }} Merck &amp; Co., Inc., Rahway, NJ, USA and its
            affiliates. All rights reserved.
          </p>
        </div>
      </div>
    </div>
    <EnterModal />
  </footer>
</template>

<script>
import EnterModal from '@/components/EnterModal.vue';
export default {
  name: 'Footer',
  components: {
    EnterModal,
  },
  props: {
    msg: String,
  },
  mounted() {},
  computed: {
    currYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
