<template>
  <div class="enter-modal-wrapper container py-5 my-3">
    <div
      class="modal interstitial"
      id="enter-modal"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-inner">
            <div class="modal-header">
              <h5 class="modal-title" id="myModalLabel">
                You are leaving the MSD Grant Portal
              </h5>
            </div>
            <div class="modal-body">
              <p>
                You are now leaving this website. By continuing, you will be
                directed to a site intended only for residents of the United
                States and Canada. We are called MSD everywhere, except in the
                United States and Canada where we are known as Merck & Co.,
                Inc., Rahway, NJ, USA
              </p>

              <div class="modal-ctas">
                <button class="btn-std" @click="closeWindow()">Cancel</button>
                <button
                  class="btn-std"
                  data-bs-dismiss="modal"
                  >Continue</button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';

const showLogs = false;

export default {
  name: 'EnterModal',
  props: {
    msg: String,
  },
  methods: {
    closeWindow() {
      window.close();
    },
  },
  mounted() {
    // Referrer only contains domain info for web apps that use
    // client-side routing. Example (no route recorded after TLD .com):
    // https://brc-dev.apps.americas.cf.webappsp.com/
    showLogs && console.log('referrer is: ', document.referrer);

    // const prior = 'localhost';
    // const prior = 'brc-dev';
    const prior = 'msd';
    const priorIsMSD = document.referrer.includes(prior);
    // const priorIsMSD = true;
    showLogs && console.log('prior search fragment is: ', prior);
    showLogs && console.log('priorIsMSD is: ', priorIsMSD);

    if (priorIsMSD) {
      const enterModal = new bootstrap.Modal(document.getElementById('enter-modal'));
      enterModal.show();
    }
  },
};
</script>

