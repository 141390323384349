import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Patient Advocacy - Home',
    },
    metaTags: [
      {
        name: '',
        content: '',
      },
      {
        property: '',
        content: '',
      },
    ],
  },

  {
    path: '/grant-types',
    name: 'grant-types',
    meta: {
      title: 'Patient Advocacy - Grant types',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "grant-types" */ '../views/GrantTypes.vue'),
  },
  {
    path: '/learn-about-process',
    name: 'learn-about-process',
    meta: {
      title: 'Patient Advocacy - Learn about the process',
    },
    component: () =>
      import(
        /* webpackChunkName: "learn-about-process" */ '../views/LearnAboutProcess.vue'
      ),
  },
  {
    path: '/after-you-apply',
    name: 'after-you-apply',
    meta: {
      title: 'Patient Advocacy - After you apply',
    },
    component: () =>
      import(
        /* webpackChunkName: "after-you-apply" */ '../views/AfterYouApply.vue'
      ),
  },
  {
    path: '/faqs',
    name: 'faqs',
    meta: {
      title: 'Patient Advocacy - Faqs',
    },
    component: () => import(/* webpackChunkName: "faqs" */ '../views/Faqs.vue'),
    props: true
  },
  {
    path: '/uspa-entry',
    name: 'uspa-entry',
    meta: {
      title: 'Patient Advocacy - USPA Entry',
    },
    component: () => import(/* webpackChunkName: "uspa-entry" */ '../views/USPAEntry.vue'),
    props: true
  },
  {
    path: '/sitemap',
    name: 'sitemap',
    meta: {
      title: 'Patient Advocacy - Sitemap',
    },
    component: () =>
      import(/* webpackChunkName: "sitemap" */ '../views/Sitemap.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,

  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        x: 500,
        y: 500,
        //el: '#main',
      };
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

// Checks Meta Titles. This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
